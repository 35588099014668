export enum DOMAIN_NUMBER  {
    TIKROCKET_PRD = 0,
    TIKROCKET_STAGING = 1,
    LOCALHOST = 2,
    TIKFAME_PRD = 4,
    TIKFAME_STAGING = 5,
}
export const DOMAINS : Record<DOMAIN_NUMBER, string>= {
    [DOMAIN_NUMBER.TIKROCKET_PRD]: 'https://www.tikrocket.co/dashboard',
    [DOMAIN_NUMBER.TIKROCKET_STAGING]: 'https://www.app-staging.tikrocket.co/dashboard',
    [DOMAIN_NUMBER.LOCALHOST]: 'http://localhost:3001/dashboard',
    [DOMAIN_NUMBER.TIKFAME_PRD]: 'https://www.tikfame.co/dashboard',
    [DOMAIN_NUMBER.TIKFAME_STAGING]: 'https://www.app-staging.tikfame.co/dashboard',
};
